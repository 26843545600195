import React, { useState } from 'react';
import {    MenuItem, Select, Grid } from '@mui/material'; 
import { MAIN_COLOR } from 'common/sharedFunctions';
import { colors } from './Theme/WebTheme';

const BookingModeToggle = ({ isPrepaid, setIsPrepaid, prepaidText, postpaidText, isRTL }) => {
  
 
  const [bookingType, setBookingType] = useState(isPrepaid ? 'prepaid' : 'postpaid');

  const handleBookTypeSelect = (event) => {
    const selectedType = event.target.value;
    setBookingType(selectedType);
    setIsPrepaid(selectedType === 'prepaid');
  };

  const handleSelect = (isPrepaid) => {
    setIsPrepaid(isPrepaid);
    setBookingType(isPrepaid ? 'prepaid' : 'postpaid'); 
  };

  return (
    <Grid item xs={12} sm={6}>
      <Select
        id="booking-type-native"
        value={bookingType}
        onChange={handleBookTypeSelect}
        style={{ textAlign: isRTL==='rtl' ? 'right' : 'left' }}
        sx={{
          color: colors.BLACK,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: MAIN_COLOR,
          },
        }}
        variant="outlined"
        fullWidth
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="prepaid" onClick={() => handleSelect(true)} sx={styles.menuItem}>
          {prepaidText}
        </MenuItem>
        <MenuItem value="postpaid" onClick={() => handleSelect(false)} sx={styles.menuItem}>
          {postpaidText}
        </MenuItem>
      </Select>
    </Grid>
  );
};

const styles = {
  container: {
    padding: '10px',
    backgroundColor: '#fafafa',
    borderRadius: '12px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    maxWidth: '320px',
    margin: '20px auto',
    width: '100%',
  },
  buttonGroup: {
    display: 'flex',
    width: '100%',
    borderRadius: '8px',
  },
  button: {
    fontWeight: '500',
    fontSize: '16px',
    padding: '12px 0',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    fontFamily: 'inherit',
  },
  activeButton: {
    backgroundColor: '#4caf50',
    color: '#fff !important',
  },
  inactiveButton: {
    backgroundColor: '#ffffff',
    color: '#333',
  },
  menuItem: {
    fontFamily: 'inherit',
    textAlign: 'left',
    padding: '10px 20px',
  },
};

export default BookingModeToggle;
