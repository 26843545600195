export const FirebaseConfig = {
	"projectId": "sawiride",
	"appId": "1:428807829639:web:8a21a40a35e95145dd6022",
	"databaseURL": "https://sawiride-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "sawiride.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyDCZir8-9pAVdZafqQ2Fycrl_7kgfRRWBE",
	"authDomain": "sawiride.firebaseapp.com",
	"messagingSenderId": "428807829639",
	"measurementId": "G-C5KGF58K6R"
};